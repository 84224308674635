import {
  LIST_LAYOUT_ID_PATH,
  LIST_NUMBER_OF_POSTS_PATH,
  IS_LIST_POST_NUMBERS_ENABLED_PATH,
  getAppSettingsValue,
} from '@wix/communities-forum-client-commons';

import { LAYOUT_ID_DEFAULT } from '../constants/layouts';

export const getListLayoutId = (state, style) =>
  getAppSettingsValue({ style, key: LIST_LAYOUT_ID_PATH, fallback: LAYOUT_ID_DEFAULT });

export const getListNumberOfPosts = (state, style) =>
  getAppSettingsValue({ style, key: LIST_NUMBER_OF_POSTS_PATH, fallback: 3 });

export const getIsListPostNumbersEnabled = (state, style) =>
  getAppSettingsValue({ style, key: IS_LIST_POST_NUMBERS_ENABLED_PATH, fallback: true });
