import PropTypes from 'prop-types';
import React from 'react';

export const CommentDisabledIconSmall = ({ title, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
    <title>{title}</title>
    <path
      fill-rule="evenodd"
      d="M2.723 3.053L2.8 3.1l1.414 1.06C4.455 4.057 4.721 4 5 4h7c1.105 0 2 .895 2 2v4c0 .45-.149.866-.4 1.2l1.2.9c.22.166.266.48.1.7-.147.196-.411.254-.623.147L14.2 12.9l-1.414-1.06c-.241.103-.507.16-.786.16H9.667l-3.899 2.924c-.268.211-.762.067-.768-.384V12c-1.105 0-2-.895-2-2V6c0-.45.149-.866.4-1.2l-1.2-.9c-.22-.166-.266-.48-.1-.7.147-.196.411-.254.623-.147zM4 6v4c0 .552.448 1 1 1h1v2.5L9.333 11h2.334L4.2 5.4c-.126.167-.2.375-.2.6zm8-1H5.333l7.467 5.6c.126-.167.2-.375.2-.6V6c0-.552-.448-1-1-1z"
    />
  </svg>
);

CommentDisabledIconSmall.propTypes = {
  title: PropTypes.string,
};
