import { EmotionsButton, LikeButton } from '@wix/comments-ooi-client/reactions';
import { ReactionCode } from '@wix/comments-ooi-client/reaction-types';
import React, { useMemo } from 'react';
import { REACT_TO_POST } from '../../../constants/interactions';
import { ReactionsDisplayState } from '../../../containers/post-reactions/post-reaction-types';
import { ReactionsConfig } from '../../../containers/post-reactions/post-reactions-config.utils';
import { useEnsureAuth } from '../../../hooks/use-ensure-auth';

export const ReactionsButton: React.FC<{
  reactions: ReactionsDisplayState;
  reactionsConfig: ReactionsConfig;
  forPublicUser: any;
  onReactionClick: (reactionCode: ReactionCode) => void;
  postId: string;
  t: any;
  isMobile: boolean;
}> = ({ reactions, reactionsConfig, forPublicUser, onReactionClick, postId, t, isMobile }) => {
  const forAuthenticatedUser = useEnsureAuth();

  const props = useMemo(
    () => ({
      isDisabled: reactions.pending,
      onClick: (reactionCode: ReactionCode) =>
        forAuthenticatedUser(
          forPublicUser(() => onReactionClick(reactionCode)),
          {
            actionDetails: {
              action: REACT_TO_POST,
              args: [{ postId, reactionCode }],
            },
          },
        ),
      t,
      isMobile,
      activeReaction: reactions.activeReaction,
    }),
    [forAuthenticatedUser, forPublicUser, isMobile, onReactionClick, postId, reactions, t],
  );

  switch (reactionsConfig.type) {
    case 'emotions':
      return <EmotionsButton reactions={reactions.reactions} config={reactionsConfig} {...props} />;
    case 'likes':
      return <LikeButton config={reactionsConfig} {...props} />;
    case 'none':
      return null;
  }
};
