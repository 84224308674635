import PropTypes from 'prop-types';
import React from 'react';

export const CommentDisabledIcon = ({ title = '', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" {...props}>
    <title>{title}</title>
    <path
      fill-rule="evenodd"
      d="M8.386 13C8.262 13 8.142 13.046 8.05 13.13L4.5 16.347V13.5C4.5 13.224 4.276 13 4 13H2C1.724 13 1.5 12.775 1.5 12.5V3.5C1.5 3.225 1.724 3 2 3H2.293L12.293 13H8.386ZM14 3C14.276 3 14.5 3.225 14.5 3.5V12.5C14.5 12.775 14.276 13 14 13H13.706L3.706 3H14ZM16 15.293L14.587 13.88C15.123 13.651 15.5 13.119 15.5 12.5V3.5C15.5 2.673 14.827 2 14 2H2.706L0.706 0L0 0.707L1.413 2.12C0.877 2.349 0.5 2.881 0.5 3.5V12.5C0.5 13.327 1.173 14 2 14H3.5V16.347C3.5 16.748 3.728 17.099 4.094 17.261C4.227 17.32 4.366 17.349 4.504 17.349C4.745 17.349 4.982 17.26 5.171 17.089C5.171 17.089 5.171 17.088 5.172 17.088L8.579 14H13.293L15.293 16L16 15.293Z"
    />
  </svg>
);

CommentDisabledIcon.propTypes = {
  title: PropTypes.string,
};
