import classNames from 'classnames';
import React from 'react';
import CounterNumber from '../../counter-number';
import { ViewCountIcon } from '../../icons/view-count-icon';
import styles from './post-metadata.scss';

type PostViewsCountProps = {
  post: any;
  t: any;
  showViewCount: boolean;
};

export const PostViewsCount: React.FC<PostViewsCountProps> = ({ post, t, showViewCount }) => {
  return (
    <div className={styles.numberElementWithIcon} data-hook="post-list-item__view-count">
      <ViewCountIcon
        title={t('subcategory-list-item.views')}
        className={classNames(styles.icon, 'forum-icon-fill')}
      />
      <span>
        {showViewCount ? <CounterNumber entityId={post._id} viewCount /> : <span>&nbsp;</span>}
      </span>
    </div>
  );
};
