import React from 'react';
import { MemberCardAsyncLoadable } from './member-card-async-loadable';

let hasLoaded = false;
export default class MemberCardAsync extends React.Component {
  state = { shouldRender: hasLoaded };
  renderCard = () => {
    if (this.state.shouldRender) {
      return;
    }
    MemberCardAsyncLoadable.load().then(() => {
      hasLoaded = true;
      this.setState({ shouldRender: true });
    });
  };

  render() {
    const { children, ...rest } = this.props;
    return hasLoaded || this.state.shouldRender ? (
      <MemberCardAsyncLoadable {...rest}>{children}</MemberCardAsyncLoadable>
    ) : (
      <div {...rest} onMouseOver={this.renderCard}>
        {children}
      </div>
    );
  }
}
