import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import RecentPostsItem from '../recent-posts-item';
import styles from './recent-posts-list-item.scss';
import { getIsViewsCountEnabled } from '../../../app/selectors/app-settings-selectors';
import withAuth from '../../../app/hoc/with-auth';
import withDeviceType from '../../../app/hoc/with-device-type';

export class RecentPostsListItem extends Component {
  render() {
    const { post, isPostNumbersEnabled, forPublicUser, isMobile, isDesktop } = this.props;

    const componentProps = {
      post,
      forPublicUser,
      isMobile,
      ...this.props,
      isPostNumbersEnabled,
    };

    const itemClassName = classNames(
      styles.listItem,
      'forum-card-background-color',
      'recent-posts-list__list-item',
      {
        [styles.desktopListItem]: isDesktop,
        [styles.mobileListItem]: isMobile,
        [styles.withFooter]: isPostNumbersEnabled,
      },
    );

    return (
      <div className={itemClassName}>
        <article className={styles.container} data-hook="recent-post-list-item">
          <RecentPostsItem {...componentProps} />
        </article>
      </div>
    );
  }
}

RecentPostsListItem.propTypes = {
  post: PropTypes.object.isRequired,
  isPostNumbersEnabled: PropTypes.bool,
  forPublicUser: PropTypes.func.isRequired,
  withCover: PropTypes.bool.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

RecentPostsListItem.defaultProps = {
  titleFontClassName: 'forum-post-title-font',
  titleFontColorClassName: 'forum-post-title-color',
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
});

// prettier-ignore
export default flowRight(
  withAuth,
  withDeviceType,
  connect(mapRuntimeToProps),
)(RecentPostsListItem);
