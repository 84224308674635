import RecentPostsWidgetRoot from '../../../recent-posts-widget/components/recent-posts-widget-root';
import { withLayoutUpdate } from '../../../common/hoc/with-layout-update';
import { withInvalidAppState } from '../../../common/hoc/with-invalid-app-state';
import InvalidAppStatePage from '../../../app/components/invalid-app-state-page';
import { withErrorState } from '../../../common/hoc/with-error-state';

export default withErrorState(
  withLayoutUpdate(withInvalidAppState(RecentPostsWidgetRoot, InvalidAppStatePage)),
  InvalidAppStatePage,
);
