import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getCounter } from '../../selectors/counters-selectors';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { getIsQaMode } from '../../../common/store/location/location-selectors';

class CounterNumber extends React.PureComponent {
  render() {
    const { t, tKey, tParam, counterValue, screenShotTest } = this.props;

    if (screenShotTest) {
      return `<#>`;
    }

    if (counterValue === undefined || counterValue === null) {
      return null;
    }

    return tKey ? t(tKey, { [tParam]: counterValue }) : t('number', { count: counterValue });
  }
}

CounterNumber.propTypes = {
  entityId: PropTypes.string,
  initialValue: PropTypes.number,
  tKey: PropTypes.string,
  tParam: PropTypes.string,
  language: PropTypes.string,
  totalPosts: PropTypes.bool,
  viewCount: PropTypes.bool,
  likeCount: PropTypes.bool,
  commentsOnly: PropTypes.bool,
  totalComments: PropTypes.bool,
  totalReplies: PropTypes.bool,
  screenShotTest: PropTypes.bool,
};

CounterNumber.defaultProps = {
  tParam: 'count',
  totalPosts: false,
  viewCount: false,
  likeCount: false,
  commentsOnly: false,
  totalComments: false,
  totalReplies: false,
};

function extractCounters(
  counters,
  { totalPosts, viewCount, likeCount, commentsOnly, totalComments, totalReplies },
) {
  if (!counters) {
    return;
  }

  if (totalPosts) {
    return counters.totalPosts;
  } else if (viewCount) {
    return counters.totalViews || counters.viewCount; // totalViews is legacy support
  } else if (likeCount) {
    return counters.likeCount;
  } else if (commentsOnly) {
    return counters.totalComments !== undefined
      ? counters.totalComments - (counters.totalReplies || 0)
      : undefined;
  } else if (totalComments) {
    return counters.totalComments;
  } else if (totalReplies) {
    return counters.totalReplies;
  } else {
    console.error('[CounterNumber] no counter specified');
  }
}

const mapDispatchToProps = (state, ownProps) => {
  const counterValue = extractCounters(getCounter(state, ownProps.entityId), ownProps);

  return {
    counterValue: counterValue !== undefined ? counterValue : ownProps.initialValue,
    language: getLanguage(state),
    screenShotTest: getIsQaMode(state),
  };
};

export default flowRight(
  connect(mapDispatchToProps, [REDUCERS.COUNTERS]),
  withTranslate,
)(CounterNumber);
