import { MARKED_COMMENT_ICON_TYPES } from '@wix/communities-forum-client-commons';
import Select from 'wix-ui-icons-common/on-stage/Select';
import Badge from 'wix-ui-icons-common/on-stage/Badge';
import LikeHeart from 'wix-ui-icons-common/on-stage/LikeHeart';
import Reaction from 'wix-ui-icons-common/on-stage/Reaction';
import Favorite from 'wix-ui-icons-common/on-stage/Favorite';
import Tag from 'wix-ui-icons-common/on-stage/Tag';

export const getBestAnswerIcon = (markedCommentIcon: keyof typeof MARKED_COMMENT_ICON_TYPES) => {
  const Icon = {
    [MARKED_COMMENT_ICON_TYPES.CHECKMARK]: Select,
    [MARKED_COMMENT_ICON_TYPES.CHECKBADGE]: Badge,
    [MARKED_COMMENT_ICON_TYPES.HEART]: LikeHeart,
    [MARKED_COMMENT_ICON_TYPES.SMILE]: Reaction,
    [MARKED_COMMENT_ICON_TYPES.STAR]: Favorite,
    [MARKED_COMMENT_ICON_TYPES.TAG]: Tag,
    [MARKED_COMMENT_ICON_TYPES.NONE]: null,
  }[markedCommentIcon];

  return Icon === undefined ? Select : Icon;
};
