import PropTypes from 'prop-types';
import React from 'react';
import EmptyState from './empty-state';
import withTranslate from '../../../common/components/with-translate/with-translate';

const AuthenticatedNoAccess = ({ t }) => {
  return (
    <EmptyState
      title={t('recent-posts-widget.empty-state.authenticated-no-access.title')}
      content={t('recent-posts-widget.empty-state.authenticated-no-access.content')}
    />
  );
};

AuthenticatedNoAccess.propTypes = {
  t: PropTypes.func,
};

export default withTranslate(AuthenticatedNoAccess);
