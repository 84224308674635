import React from 'react';

export const LoginIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fill="#162D3D"
      d="M42 29v2c0 .552-.448 1-1 1H31c-.552 0-1-.448-1-1v-2c0-2.565 1.61-4.753 3.873-5.612C32.748 22.68 32 21.428 32 20c0-2.21 1.79-4 4-4s4 1.79 4 4c0 1.427-.748 2.68-1.873 3.388C40.391 24.247 42 26.435 42 29zm-7-6.171V24.1c-2.282.464-4 2.481-4 4.9v2h10v-2c0-2.419-1.718-4.436-4-4.9v-1.271c1.165-.412 2-1.522 2-2.829 0-1.657-1.343-3-3-3s-3 1.343-3 3c0 1.307.835 2.417 2 2.829z"
      transform="translate(-24 -12)"
    />
  </svg>
);
