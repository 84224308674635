import { loadLazyComponent } from '@wix/communities-forum-client-commons';
import React from 'react';

export const MemberCardAsyncLoadable = loadLazyComponent(
  () => import(/* webpackChunkName: "member-card-async-frameless" */ '../member-card'),
  {
    // @ts-expect-error
    loading: (props: any) => <div className={props.className}>{props.children}</div>,
  },
);
