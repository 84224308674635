import { MARKED_COMMENT_TYPES } from '@wix/communities-forum-client-commons';

export const getMarkedCommentLabel = (config = {}, t) => {
  if (!t) {
    return null;
  }

  const isValidKey = Object.values(MARKED_COMMENT_TYPES).includes(config.type);
  const labelKey = isValidKey ? config.type : MARKED_COMMENT_TYPES.BEST_ANSWER;

  return {
    [MARKED_COMMENT_TYPES.BEST_ANSWER]: t('comment-marked-type.best-answer'),
    [MARKED_COMMENT_TYPES.SOLVED]: t('comment-marked-type.solved'),
    [MARKED_COMMENT_TYPES.FEATURED]: t('comment-marked-type.featured'),
    [MARKED_COMMENT_TYPES.PINNED]: t('comment-marked-type.pinned'),
    [MARKED_COMMENT_TYPES.CUSTOM]: config?.customLabel,
  }[labelKey];
};
