import React from 'react';
import classNames from 'classnames';
import IconTooltip from '../icon-tooltip';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CommentDisabledIcon } from '../icons/comment-disabled-icon';
import styles from './locked-commenting-indicator.scss';

type LockedCommentingIconProps = {
  className?: string;
};

export const LockedCommentingIndicator: React.FC<LockedCommentingIconProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <IconTooltip text={t('post-list-item.post-closed')} className={styles.tooltip}>
      <CommentDisabledIcon className={classNames(className, 'icon-secondary-fill')} />
    </IconTooltip>
  );
};
