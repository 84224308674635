import { connect } from '../../common/components/runtime-context';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { REDUCERS } from '../../common/components/runtime-context/reducers';
import { openNativeShareIfSupported } from '../../common/services/native-share';

export default function withNativeShare(WrappedComponent) {
  const mapRuntimeToProps = (state) => {
    const isMobile = getIsMobile(state);
    return {
      openNativeShareIfSupported: isMobile
        ? openNativeShareIfSupported
        : (_, fallback) => fallback(),
    };
  };

  return connect(mapRuntimeToProps, [REDUCERS.BASIC_PARAMS])(WrappedComponent);
}
