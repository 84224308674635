import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './bordered-tooltip.scss';

const BorderedTooltip = ({
  children,
  style,
  className,
  offset,
  placement,
  arrowOffsetLeft,
  componentOffsetLeft,
}) => {
  style[placement] -= offset;
  style.left -= componentOffsetLeft;
  return (
    <div
      className={classNames(styles.container, { [styles[placement]]: !!placement }, className)}
      style={style}
    >
      {children}
      <span
        className={styles.triangle}
        style={{ left: `calc(${arrowOffsetLeft} + ${componentOffsetLeft}px)` }}
      />
    </div>
  );
};

BorderedTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  offset: PropTypes.number,
  componentOffsetLeft: PropTypes.number,
  placement: PropTypes.string,
  arrowOffsetLeft: PropTypes.string,
};

BorderedTooltip.defaultProps = {
  placement: 'top',
  offset: 10,
  componentOffsetLeft: 0,
};

export default BorderedTooltip;
