import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import Link from '../link/internal-link';
import IconTooltip from '../icon-tooltip';
import styles from './best-answer-icon-link.scss';
import { buildNewDeepCommentUrl } from '../../services/build-deep-comment-url';
import { getCategory } from '../../../common/selectors/categories-selectors';
import { getBestAnswerIcon } from '../../services/get-best-answer-icon';

const BestAnswerIconLink = ({
  t,
  toolTipClassName,
  toolTipContent,
  iconClassName,
  linkComponent: LinkComponent,
  commentId,
  post,
  categorySlug,
  markedCommentIcon,
}) => {
  const Icon = getBestAnswerIcon(markedCommentIcon);

  return (
    Icon && (
      <IconTooltip text={toolTipContent || t('comment.solved')} className={toolTipClassName}>
        <LinkComponent
          className={styles.link}
          to={buildNewDeepCommentUrl(categorySlug, post.slug, commentId)}
          aria-label={t('comment.solved.label')}
        >
          <Icon className={classNames(iconClassName, 'button-fill')} />
        </LinkComponent>
      </IconTooltip>
    )
  );
};

BestAnswerIconLink.propTypes = {
  commentId: PropTypes.string, // TODO: remove this prop and all usage when merging specs.comments.EnableForumNewWixComments
  toolTipClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  linkComponent: PropTypes.any,
  t: PropTypes.func,
  post: PropTypes.object,
  categorySlug: PropTypes.string,
  markedCommentIcon: PropTypes.string,
};

BestAnswerIconLink.defaultProps = {
  linkComponent: Link,
};

const mapRuntimeToProps = (state, ownProps) => {
  const category = getCategory(state, ownProps.post.categoryId) || {};
  return {
    categorySlug: category.slug,
    markedCommentIcon: category.commentsMark?.icon,
  };
};

export default flowRight(connect(mapRuntimeToProps))(BestAnswerIconLink);
