import { flowRight } from 'lodash';
import { connect } from '../../common/components/runtime-context';
import withHocName from '../../app/hoc/with-hoc-name';
import withDeviceType from '../../app/hoc/with-device-type';
import {
  getLayoutName,
  getLayoutConfig,
  getPostsPerPage,
} from '../selectors/layout-config-selectors';
import { getIsListPostNumbersEnabled } from '../selectors/app-settings-selectors';

const mapRuntimeToProps = (state, { isMobile }, actions, host) => ({
  layoutName: getLayoutName({ state, isMobile, style: host.style }),
  layoutConfig: getLayoutConfig({ state, isMobile, style: host.style }),
  numberOfPosts: getPostsPerPage(state, host.style),
  isPostNumbersEnabled: getIsListPostNumbersEnabled(state, host.style),
});

// prettier-ignore
export default flowRight(
  withDeviceType,
  connect(mapRuntimeToProps),
  withHocName('WithLayoutProps')
);
