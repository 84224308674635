import { LAYOUT_NAME_HORIZONTAL, LAYOUT_NAME_VERTICAL } from '../constants/layouts';

import { LIST_NUMBER_OF_POSTS } from '@wix/communities-forum-client-commons';

const LAYOUT_CONFIG = {
  [LAYOUT_NAME_VERTICAL]: {
    postsNumberSettingsKey: LIST_NUMBER_OF_POSTS,
    initialWidth: 340,
  },
  [LAYOUT_NAME_HORIZONTAL]: {
    postsNumberSettingsKey: LIST_NUMBER_OF_POSTS,
    initialWidth: 980,
  },
};

export const getLayoutConfig = (layout) => LAYOUT_CONFIG[layout];
