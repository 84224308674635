export function openNativeShareIfSupported({ title, url }, fallback) {
  if (typeof navigator !== 'undefined' && navigator.share) {
    navigator.share({
      ...(title && { title }),
      url,
    });
  } else {
    return fallback();
  }
}
