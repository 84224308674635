import React from 'react';

export const EmotionsIcon = ({ title, ...props }: any) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{title}</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 0C13.187 0 17 3.813 17 8.5C17 13.187 13.187 17 8.5 17C3.813 17 0 13.187 0 8.5C0 3.813 3.813 0 8.5 0ZM8.5 1C4.364 1 1 4.364 1 8.5C1 12.636 4.364 16 8.5 16C12.636 16 16 12.636 16 8.5C16 4.364 12.636 1 8.5 1ZM5.352 9.6248C5.933 10.8638 7.139 11.6338 8.5 11.6338C9.824 11.6338 11.016 10.8938 11.611 9.7008L12.505 10.1488C11.741 11.6818 10.206 12.6338 8.5 12.6338C6.773 12.6338 5.183 11.6188 4.447 10.0488L5.352 9.6248ZM5.5 5.5C6.052 5.5 6.5 5.947 6.5 6.5C6.5 7.053 6.052 7.5 5.5 7.5C4.948 7.5 4.5 7.053 4.5 6.5C4.5 5.947 4.948 5.5 5.5 5.5ZM11.5 5.5C12.052 5.5 12.5 5.947 12.5 6.5C12.5 7.053 12.052 7.5 11.5 7.5C10.948 7.5 10.5 7.053 10.5 6.5C10.5 5.947 10.948 5.5 11.5 5.5Z"
    />
  </svg>
);
