import React from 'react';

export const NotificationFollowingIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M12.5 3c.828 0 1.5.672 1.5 1.5v.708l.193.058C16.403 5.98 18 8.053 18 10.5v2.882c0 .682.514 1.085.724 1.17.907.46 1.276 1.327 1.276 2.066V17c0 .552-.448 1-1 1h-4.05c-.232 1.141-1.24 2-2.45 2-1.21 0-2.218-.859-2.45-2H6c-.552 0-1-.448-1-1v-.382c0-.816.43-1.567 1.124-1.982.584-.281.876-.7.876-1.254V10.5c0-2.518 1.692-4.64 4-5.293V4.5c0-.828.672-1.5 1.5-1.5z"
      transform="translate(-24 -12) translate(24 12)"
    />
  </svg>
);
