import classNames from 'classnames';
import React from 'react';
import { EmotionsIcon } from '../../icons/emotions-icon';
import styles from './post-metadata.scss';

export const EmotionsCount: React.FC<{ count: number; t: any; className: string }> = ({
  count,
  t,
  className,
}) => {
  return (
    <div className={styles.numberElementWithIcon} data-hook="emotions-count">
      <EmotionsIcon
        title={t('subcategory-list-item.reactions')}
        className={classNames(styles.icon, 'forum-icon-fill', className)}
      />
      <span>{count}</span>
    </div>
  );
};
