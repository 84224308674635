import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import RecentPostList from '../recent-posts-list';
import styles from './recent-posts-desktop.scss';

const RecentPostsDesktop = ({ posts, isPostNumbersEnabled }) =>
  isEmpty(posts) ? null : (
    <div className={classNames(styles.container, 'forum-text-color')}>
      <RecentPostList posts={posts} isPostNumbersEnabled={isPostNumbersEnabled} />
    </div>
  );

RecentPostsDesktop.propTypes = {
  t: PropTypes.func,
  posts: PropTypes.array.isRequired,
  isPostNumbersEnabled: PropTypes.bool,
};

export default RecentPostsDesktop;
