import React from 'react';
import classNames from 'classnames';
import { HorizontalSeparator } from '../../../app/components/separator';
import withCardBorderColor from '../../hoc/with-card-border-color';
import { ReactionsButton } from '../../../app/components/post-footer/action-buttons/reactions-button';
import { useReactionsConfig } from '../../../app/hooks/use-reactions-config';
import { PostMetadata } from './post-metadata';
import { classes } from './post-reactions.st.css';
import styles from './post-item-footer-new.scss';
import { ReactionsDisplayState } from '../../../app/containers/post-reactions/post-reaction-types';
import { ReactionCode } from '@wix/comments-ooi-client/reaction-types';

const Separator = withCardBorderColor(HorizontalSeparator);

type PostItemFooterNewProps = {
  reactions: ReactionsDisplayState;
  onReactionClick: (reactionCode: ReactionCode) => void;
  post: any;
  category: any;
  titleFontClassName: string;
  titleFontColorClassName: string;
  isMobile: boolean;
  t: any;
  forPublicUser: any;
};

export const PostItemFooterNew: React.FC<PostItemFooterNewProps> = ({
  reactions,
  forPublicUser,
  onReactionClick,
  post,
  category,
  isMobile,
  titleFontClassName,
  titleFontColorClassName,
  t,
}) => {
  const reactionsConfig = useReactionsConfig(category);
  const showReactions = reactionsConfig.type !== 'none';

  return (
    <div className={classNames(styles.footerWrapper, titleFontClassName, titleFontColorClassName)}>
      <Separator className={styles.separator} />
      <div className={classNames(styles.container, classes.rootReactionsVars)}>
        <div className={styles.leftSide}>
          {showReactions && (
            <div className={styles.action}>
              <ReactionsButton
                reactions={reactions}
                reactionsConfig={reactionsConfig}
                forPublicUser={forPublicUser}
                onReactionClick={onReactionClick}
                postId={post._id}
                t={t}
                isMobile={!!isMobile}
              />
            </div>
          )}
        </div>
        <div className={styles.rightSide}>
          <PostMetadata
            post={post}
            t={t}
            reactionsCount={reactions.total}
            isMobile={isMobile}
            showReactionsCount={showReactions}
          />
        </div>
      </div>
    </div>
  );
};
