import {
  EmotionsConfig,
  LikesConfig,
  MainReactionName,
  NoReactions,
  ReactionName,
} from '@wix/comments-ooi-client/reaction-types';
import { isDefined, unreachable } from '../../../utils/ts-utils';

export type ReactionsConfig = NoReactions | LikesConfig | EmotionsConfig;

type ForumPostReactionName = 'love' | 'thumbs_up';
type ForumAdditionalPostReactions =
  | 'wow'
  | 'sad'
  | 'haha'
  | 'angry'
  | 'clap'
  | 'thumbs_up'
  | 'thinking'
  | 'love'
  | 'smile';

type PostInteraction = 'reaction' | 'none';

type PostReactionsConfigProps = Partial<{
  postInteraction: 'none' | 'reaction';
  mainPostReaction: {
    type: ForumPostReactionName;
  };
  additionalPostReactions: {
    type: ForumAdditionalPostReactions;
  }[];
}>;

const MAX_ADDITIONAL_REACTIONS = 5;

export const resolvePostReactionsConfig = ({
  postInteraction,
  mainPostReaction,
  additionalPostReactions,
}: PostReactionsConfigProps): ReactionsConfig => {
  additionalPostReactions = additionalPostReactions || [];

  const reactionType = getReactionType({
    postInteraction,
    isAdditionalReactionsAvailable: additionalPostReactions.length > 0,
  });

  switch (reactionType) {
    case 'none':
      return { type: 'none' };
    case 'likes':
      return { type: 'likes', mainEmotion: transformMainEmotionName(mainPostReaction?.type) };
    case 'emotions':
      const additionalReactions = additionalPostReactions
        .map((reaction) => transformAdditionalReactionName(reaction.type))
        .filter(isDefined)
        .slice(0, MAX_ADDITIONAL_REACTIONS);

      return {
        type: 'emotions',
        mainEmotion: transformMainEmotionName(mainPostReaction?.type),
        emotions: additionalReactions as EmotionsConfig['emotions'],
      };
    default:
      throw unreachable(reactionType);
  }
};

const transformMainEmotionName = (reactionName?: ForumPostReactionName): MainReactionName => {
  switch (reactionName) {
    case 'love':
      return 'heart';
    case 'thumbs_up':
      return 'thumbsup';
    case undefined:
      return 'heart';
    default:
      console.error(unreachable(reactionName));
      return 'heart';
  }
};

export const transformAdditionalReactionName = (
  reactionName?: ForumAdditionalPostReactions,
): ReactionName | undefined => {
  switch (reactionName) {
    case 'angry':
    case 'clap':
    case 'sad':
    case 'thinking':
      return reactionName;
    case 'thumbs_up':
      return 'thumbsup';
    case 'love':
      return 'heart';
    case 'smile':
      return 'smiley';
    case 'wow':
      return 'surprised';
    case 'haha':
      return 'lol';
    case undefined:
      return undefined;
    default:
      console.error(unreachable(reactionName));
      return undefined;
  }
};

const getReactionType = ({
  postInteraction,
  isAdditionalReactionsAvailable,
}: {
  postInteraction?: PostInteraction;
  isAdditionalReactionsAvailable: boolean;
}) => {
  const defaultReactionType = isAdditionalReactionsAvailable ? 'emotions' : 'likes';
  switch (postInteraction) {
    case 'none':
      return 'none';
    case 'reaction':
    case undefined:
      return defaultReactionType;
    default:
      unreachable(postInteraction);
      return defaultReactionType;
  }
};
