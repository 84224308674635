export const LAYOUT_ID_HORIZONTAL = 0;
export const LAYOUT_ID_VERTICAL = 1;

export const LAYOUT_ID_DEFAULT = LAYOUT_ID_VERTICAL;

export const LAYOUT_NAME_HORIZONTAL = 'horizontal';
export const LAYOUT_NAME_VERTICAL = 'vertical';

export const LAYOUT_NAMES = {
  [LAYOUT_ID_HORIZONTAL]: LAYOUT_NAME_HORIZONTAL,
  [LAYOUT_ID_VERTICAL]: LAYOUT_NAME_VERTICAL,
};

export const getLayoutName = (id) => LAYOUT_NAMES[id];
