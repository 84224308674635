import React from 'react';
import { connect } from '../../common/components/runtime-context';
import { createRtfFormatter } from '@wix/yoshi-flow-editor';
import { getLanguage } from '../../common/store/basic-params/basic-params-selectors';

let initialLanguage;
let formatRelativeTime;

export default function withRelativeTimeFormatting(WrappedComponent) {
  const LoggerComponent = ({ hasMissMatch, messagePayload, captureMessage, ...rest }) => {
    React.useEffect(() => {
      if (hasMissMatch) {
        captureMessage('Inconsistent language for RTF', messagePayload);
      }
    }, []);

    return <WrappedComponent {...rest} />;
  };

  const mapRuntimeToProps = (state, ownProps, actions) => {
    const language = getLanguage(state);
    let hasMissMatch = false;
    let messagePayload;

    if (!initialLanguage) {
      initialLanguage = language;
    }

    if (language !== initialLanguage || language !== ownProps.language) {
      hasMissMatch = true;
      messagePayload = {
        extra: {
          language,
          initialLanguage,
          ownPropsLanguage: ownProps.language,
          basicParams: state.basicParams,
          forumData: state.forumData,
        },
      };
    }

    if (!formatRelativeTime) {
      formatRelativeTime = createRtfFormatter(
        language,
        { floorSeconds: true, extraShortExpression: true },
        { style: 'narrow' },
      );
    }
    return {
      formatRelativeTime,
      ...ownProps,
      captureMessage: actions.captureMessage,
      messagePayload,
      hasMissMatch,
    };
  };

  return connect(mapRuntimeToProps)(LoggerComponent);
}
