import { flowRight } from 'lodash';
import { connect } from '../../common/components/runtime-context';

import withHocName from '../../app/hoc/with-hoc-name';
import { CARD_BORDER_WIDTH_PATH, getAppSettingsValue } from '@wix/communities-forum-client-commons';

const DEFAULT_WIDTH = 1;

const getBorderWidth = (style) => {
  return getAppSettingsValue({ style, key: CARD_BORDER_WIDTH_PATH, fallback: DEFAULT_WIDTH });
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  borderWidth: getBorderWidth(host.style),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withHocName('WithCardBorderWidth')
);
