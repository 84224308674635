import PropTypes from 'prop-types';
import React from 'react';

export const CommentIcon = ({ title, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="15"
    viewBox="0 0 17 15"
    role="img"
    {...props}
  >
    <title>{title}</title>
    <path
      d="M9.48,11.45l-4,2.88a0.84,0.84,0,0,1-1.34-.65V11.45H2.22A1.7,1.7,0,0,1,.5,9.79V2.17A1.7,1.7,0,0,1,2.22.5H14.78A1.7,1.7,0,0,1,16.5,2.17V9.79a1.7,1.7,0,0,1-1.72,1.67H9.48Z"
      transform="translate(0 0)"
      fill="none"
    />
  </svg>
);

CommentIcon.propTypes = {
  title: PropTypes.string,
};
