import PropTypes from 'prop-types';
import React from 'react';
import Link from '../../../app/components/link/internal-link';
import { connect } from '../../../common/components/runtime-context';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { getCategory } from '../../../common/selectors/categories-selectors';
import {
  FORUM_APP_ID,
} from '@wix/communities-forum-universal/dist/src/constants/appsConfig.prod';

const PostLink = ({ category, className, post, children, shouldCancelDataLink }) => (
  <Link
    className={className}
    to={`/${category?.slug}/${post?.slug}`}
    shouldCancelDataLink={shouldCancelDataLink}
  >
    {children}
  </Link>
);

PostLink.propTypes = {
  post: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const mapRuntimeToProps = (state, { post }) => ({
  category: getCategory(state, post.categoryId),
  shouldCancelDataLink: state.location.pageApplicationId === FORUM_APP_ID,
});

export default connect(mapRuntimeToProps, [REDUCERS.CATEGORIES])(PostLink);
