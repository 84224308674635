import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import withDirection, { DIRECTIONS, withDirectionPropTypes } from 'react-with-direction';
import Overlay from 'react-overlays/lib/Overlay';
import BorderedTooltip from '../bordered-tooltip';
import withFontClassName from '../../hoc/with-font-class-name';
import withSettingsColor from '../../hoc/with-settings-color';
import {
  CARD_BACKGROUND_COLOR_PATH,
  APP_TEXT_COLOR_PATH,
} from '@wix/communities-forum-client-commons';
import styles from './icon-tooltip.scss';
import { POSITION_REVERSE_MAP } from '../../constants/direction-reverse-map';

class IconTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipVisible: props.showInitially,
    };
  }

  componentDidMount() {
    this.props.hideOnTouchMove &&
      document.addEventListener('touchmove', this.handleHideOnTouchMove);
  }

  componentWillUnmount() {
    this.props.hideOnTouchMove &&
      document.removeEventListener('touchmove', this.handleHideOnTouchMove);
    this.handleTooltipHide();
  }

  handleHideOnTouchMove = () => this.hideTooltip();

  handleTooltipAppear = () => {
    this.timeout = setTimeout(() => {
      this.setState({
        isTooltipVisible: true,
      });
    }, this.props.appearDelay);
  };

  handleTooltipHide = () => {
    clearTimeout(this.timeout);
    this.hideTooltip();
  };

  hideTooltip = () =>
    this.setState({
      isTooltipVisible: false,
    });

  adjustPlacementForRTL(placement) {
    const { direction } = this.props;
    return direction === DIRECTIONS.RTL ? POSITION_REVERSE_MAP[placement] : placement;
  }

  render() {
    const {
      children,
      text,
      backgroundColor,
      borderColor,
      className,
      tooltipClassName,
      offset,
      offsetLeft,
      textColor,
      placement,
      siteColors,
      inverseColors,
      useMouseMoveHandlers,
      rootCloseEnabled,
      appearOnClick,
      contentFontClassName,
      ...props
    } = this.props;

    const tooltipClassNames = classNames(styles.tooltip, contentFontClassName, tooltipClassName);

    const Tooltip = ({ style, arrowOffsetLeft }) => (
      <BorderedTooltip
        className={tooltipClassNames}
        style={{
          color: inverseColors ? backgroundColor : textColor,
          backgroundColor: inverseColors ? textColor : backgroundColor,
          fontSize: 12,
          borderColor,
          ...style,
        }}
        placement={this.adjustPlacementForRTL(placement)}
        offset={offset}
        componentOffsetLeft={offsetLeft}
        arrowOffsetLeft={arrowOffsetLeft}
      >
        {text}
      </BorderedTooltip>
    );

    const container =
      typeof document !== 'undefined' &&
      (document.getElementById('modal-overlay') || document.getElementById('content-wrapper'));
    return (
      <div
        className={className}
        onMouseEnter={useMouseMoveHandlers && this.handleTooltipAppear}
        onMouseLeave={useMouseMoveHandlers && this.handleTooltipHide}
        onMouseDown={appearOnClick && this.handleTooltipAppear}
        {...props}
      >
        {children}
        <Overlay
          show={this.state.isTooltipVisible}
          placement={this.adjustPlacementForRTL(placement)}
          target={this}
          container={container}
          rootClose={rootCloseEnabled}
          onHide={this.hideTooltip}
        >
          <Tooltip />
        </Overlay>
      </div>
    );
  }
}

IconTooltip.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  backgroundColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  offset: PropTypes.number,
  offsetLeft: PropTypes.number,
  placement: PropTypes.string,
  inverseColors: PropTypes.bool,
  rootCloseEnabled: PropTypes.bool,
  useMouseMoveHandlers: PropTypes.bool,
  showInitially: PropTypes.bool,
  appearDelay: PropTypes.number,
  appearOnClick: PropTypes.bool,
  hideOnScroll: PropTypes.bool,
  ...withDirectionPropTypes,
};

IconTooltip.defaultProps = {
  placement: 'top',
  rootCloseEnabled: false,
  useMouseMoveHandlers: true,
  showInitially: false,
  appearDelay: 500,
  appearOnClick: false,
  hideOnTouchMove: false,
};

export default flowRight(
  withSettingsColor({
    path: CARD_BACKGROUND_COLOR_PATH,
    propName: 'backgroundColor',
    alpha: 1,
    siteColorFallback: 'color-1',
    siteColorAlpha: 1,
    fallbackColor: '#ffffff',
  }),
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'borderColor',
    alpha: 0.2,
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
  }),
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'textColor',
    alpha: 1,
    siteColorFallback: 'color-5',
  }),
  withFontClassName,
  withDirection,
)(IconTooltip);
