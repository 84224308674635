import React from 'react';
import classNames from 'classnames';
import styles from './post-metadata.scss';
import { PostViewsCount } from '../../../app/components/post-footer/metadata/post-views-count';
import { CommentButton as CommentsCount } from '../../../app/components/post-footer/action-buttons/comment-button';
import { EmotionsCount } from '../../../app/components/post-footer/metadata/emotions-count';

export const PostMetadata: React.FC<{
  post: any;
  t: any;
  showReactionsCount: boolean;
  reactionsCount: number;
  isMobile: boolean;
}> = ({ post, t, showReactionsCount, reactionsCount, isMobile }) => {
  return (
    <div className={styles.container}>
      {showReactionsCount && (
        <div className={classNames(styles.listItem)}>
          <EmotionsCount t={t} count={reactionsCount} className={styles.emotionsIcon} />
        </div>
      )}
      <div className={styles.listItem}>
        <CommentsCount post={post} t={t} type="metadata" isLink={false} />
      </div>
      {!isMobile && (
        <div className={styles.listItem}>
          <PostViewsCount post={post} showViewCount={true} t={t} />
        </div>
      )}
    </div>
  );
};
