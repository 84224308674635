import React from 'react';

export const ReportIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M680,549a9,9,0,1,1,9-9A9,9,0,0,1,680,549Zm1-12a1,1,0,0,0-2,0v4a1,1,0,0,0,2,0v-4Zm-1,6a1,1,0,1,0,1,1A1,1,0,0,0,680,543Z"
      transform="translate(-671 -531)"
    />
  </svg>
);
